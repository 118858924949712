import { EXECUTE_REQUEST } from "./global.actions.type";
import { GET_LOADING_STATUS } from "./global.getters.type";
import {
  DECREASE_LOADING_STATUS,
  INCREASE_LOADING_STATUS,
} from "./global.mutations.type";

const state = {
  requesting: 0,
};

const actions = {
  async [EXECUTE_REQUEST]({ commit }, request) {
    commit(INCREASE_LOADING_STATUS);
    try {
      return await request;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit(DECREASE_LOADING_STATUS);
    }
  },
};

const getters = {
  [GET_LOADING_STATUS]: (state) => state.requesting !== 0,
};

const mutations = {
  [INCREASE_LOADING_STATUS](state) {
    state.requesting = state.requesting + 1;
  },
  [DECREASE_LOADING_STATUS](state) {
    state.requesting = state.requesting - 1;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
