export default class Token {
  constructor(token, expiryDate) {
    this.token = token;
    this.date = expiryDate;
  }

  isExpired(now) {
    return this.date < now;
  }
}
